<template>
  <v-container class="small-container py-8">
    <v-row>
      <v-col>
        <v-card class="backgroundDark">
          <v-card-title>
            <img src="" alt="" />
            <h1 v-if="tokenData">{{ tokenData.name }}</h1>
            <v-spacer></v-spacer>
            <v-chip color="primary" small class="text-uppercase"
              >AltoStake Validator</v-chip
            >
          </v-card-title>

          <v-card-text>
            <p class="mb-0">
              {{ validator.text }}
            </p>
          </v-card-text>

          <v-expand-transition>
            <v-card-text v-if="validatorStakeData">
              <v-row class="text-center">
                <v-col>
                  <h5>Staked Amount</h5>
                  <h3 class="white--text" v-if="stakedTokens">
                    {{ formatNumber(stakedTokens, 0) }} FET
                  </h3>
                </v-col>
                <v-col>
                  <h5>Staked USD Worth</h5>
                  <h3 class="white--text" v-if="stakedUSDWorth">
                    {{ formatNumber(stakedUSDWorth, 0) }} USD
                  </h3>
                </v-col>
                <v-col>
                  <h5>Comission</h5>
                  <h3 class="white--text">{{ formatNumber(8, 0) }} %</h3>
                </v-col>
              </v-row>
            </v-card-text>
          </v-expand-transition>
          
          <v-card-actions
            class="backgroundMedium d-flex align-center px-8 py-4"
          >
            <h4>Validator Address</h4>
            <v-text-field
              readonly
              v-model="validator.address"
              class="backgroundDark d-flex align-center mx-4 pt-0"
              rounded
              hide-details
              full-width
            >
            </v-text-field>
            <v-btn
              @click="copyToClipboard(validator.address)"
              small
              color="primary"
              ><v-icon left x-small>{{
                justCopied ? "mdi-check" : "mdi-content-copy"
              }}</v-icon
              >Copy</v-btn
            >
          </v-card-actions>
        </v-card></v-col
      >
      <v-col cols="12" lg="4" md="4">
        <v-card class="backgroundDark" ref="tokenCard" v-if="!loadingTokenData">
          <v-card-title>
            <img src="" alt="" />
            <h1>{{ tokenData.symbol }}</h1>
            <v-spacer></v-spacer>
            <v-chip color="backgroundLight" small class="text-uppercase"
              >Token</v-chip
            >
          </v-card-title>
          <v-card-text v-if="tokenData">
            <v-row class="text-center">
              <v-col>
                <h5>Price</h5>
                <h3 class="white--text" v-if="tokenData.market_data">
                  {{ tokenData.market_data.price_usd | twoDecimals }}
                  <small>USD</small>
                </h3>
              </v-col>
              <v-col>
                <h5>Volume USD</h5>
                <h3 class="white--text" v-if="tokenData.market_data">
                  {{
                    tokenData.market_data.volume_last_24_hours | volumeFormatter
                  }}
                </h3>
              </v-col>
              <v-col>
                <h5>Rank #</h5>
                <h3 class="white--text" v-if="tokenData.marketcap">
                  {{ tokenData.marketcap.rank }}
                </h3>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          min-height="100"
          v-else
          class="backgroundDark d-flex align-center justify-center"
        >
          <v-progress-circular color="primary" indeterminate>
          </v-progress-circular>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" v-if="series.data">
        <v-card class="backgroundDark">
          <apexchart
            :height="$refs.tokenCard ? $refs.tokenCard.clientHeight : 275"
            :options="options"
            :series="series"
          ></apexchart>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getValidatorData } from "@/services/fetch.js";
import { formatNumber } from "../../utils/numbers";
import web3 from "web3";

export default {
  props: {
    validator: {
      type: Object,
    },
  },
  computed: {
    stakedUSDWorth() {
      if (!this.tokenData.market_data) return 0;
      return this.stakedTokens * this.tokenData.market_data.price_usd;
    },
  },
  data() {
    return {
      justCopied: false,
      tokenData: {},
      loadingTokenData: false,
      validatorData: {},
      stakedTokens: 0,
      validatorStakeData: false,
      options: {
        chart: {
          id: "vuechart-example",
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          labels: {
            show: false,
            formatter: function (value) {
              return new Date(value).toLocaleDateString("en-US");
            },
          },
          lines: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        markers: {
          colors: ["#F44336", "#E91E63", "#9C27B0"],
        },
      },
      series: [
        {
          name: "series-1",
        },
      ],
      avaxValidatorAddress: "NodeID-C8sLVqc6RZniht7aJJoeT4GzsQvrtkcsj",
    };
  },
  methods: {
    formatNumber,
    async copyToClipboard(s) {
      this.justCopied = true;
      await navigator.clipboard.writeText(s);
      setTimeout(() => {
        this.justCopied = false;
      }, 2000);
    },
    async fetchTokenData() {
      if (!this.validator.token) return;
      this.loadingTokenData = true;
      fetch(
        `https://data.messari.io/api/v1/assets/${this.validator.token}/metrics`
      )
        .then((res) => res.json())
        .then((data) => {
          this.tokenData = data.data;
          this.loadingTokenData = false;
        });

      if (this.validator.token === "fet")
        try {
          const validatorData = await getValidatorData(this.validator.address);
          this.stakedTokens = web3.utils.fromWei(validatorData.tokens);
          this.validatorStakeData = true;
        } catch (error) {
          this.validatorStakeData = false;
        }
    },
  },
  mounted() {
    this.fetchTokenData();
  },
  watch: {
    validator() {
      this.validatorStakeData = false;
      this.fetchTokenData();
    },
  },
  filters: {
    twoDecimals(val) {
      return Number(val).toFixed(2);
    },
    volumeFormatter(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + " K"; // convert to K for number from > 1000 < 1 million
      } else if (num > 1000000) {
        return (num / 1000000).toFixed(1) + " M"; // convert to M for number from > 1 million
      } else if (num < 900) {
        return num; // if value < 1000, nothing to do
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.validator-card {
  width: 100%;
}
</style>
