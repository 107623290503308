<template>
  <v-container class="small-container">
    <div class="d-flex justify-center">
      <v-chip-group v-if="wallets.length > 0" mandatory active-class="primary" v-model="selectedWallet">
        <v-chip
          v-for="(wallet, i) in wallets"
          :key="i"
          class="d-flex align-center justify-center pa-5"
          :value="wallet"
          ><img height="16px" :src="wallet.logo" alt="" />
          <span class="wallet-text">{{ wallet.name }}</span>
        </v-chip>
      </v-chip-group>
    </div>
    <v-timeline :dense="true" v-if="selectedWallet">
      <v-timeline-item
        v-for="step in selectedWallet.steps"
        :key="step.number"
        large
      >
        <template v-slot:icon>
          <v-avatar>
            <v-icon>{{ step.icon }}</v-icon>
          </v-avatar>
        </template>
        <template v-slot:opposite>
          <span>{{ step.number }}º</span>
        </template>
        <v-card class="elevation-2">
          <v-card-title class="text-h5">
            {{ step.title }}
          </v-card-title>
          <v-card-text class="text-start steps-description">
            <span v-html="step.description"></span>
          </v-card-text>
          <v-card-text class="text-start" v-html="step.extra" v-if="step.extra">
          </v-card-text>
        </v-card>
      </v-timeline-item>
      <div v-if="selectedWallet" class="d-flex justify-center mt-6">
        <v-alert icon="mdi-information" light>
          <span v-html="selectedWallet.extra"></span>
        </v-alert>
      </div>
    </v-timeline>
  </v-container>
</template>

<script>
export default {
  props: {
    wallets: {
      type: [],
      default: () => [],
    },
  },
  data() {
    return {
      selectedWallet: {},
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
  },
  mounted() {
    this.selectedWallet = this.wallets[0]
  }
};
</script>

<style lang="scss" scoped>
.steps-description {
  font-size: 1.2rem;
  line-height: 1.5rem;
}
.wallet-text {
  font-size: 1rem;
  margin-left: 10px;
}
.details-button {
  background: white;
  color: black;
  text-transform: none;
  padding: 1rem 2rem;
  border-radius: 5px;
}
.details-button:hover {
  background: rgba(255, 255, 255, 0.9);
}
.clean-link {
  text-decoration: none !important;
}
</style>
